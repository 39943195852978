import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Article from "../components/PageComponents/Article/Inspiration1"
import localize from "../components/utils/localize.js"

const IndexPage = ({ data, location, pageContext }) => (
  <Layout location={location} context={pageContext}>
    <SEO title="BMW Inspire: Empower Your Journey Music Live" />
    <Article sanity={data} />
  </Layout>
)

export default localize(IndexPage)

export const pageQuery = graphql`
  query ArticleInspiration10Query {
    img1: file(name: { eq: "BMW_INSPIRE_Pillars" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img2: file(name: { eq: "Embrace-Inspiration_Music-Live-Banner_2" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img3: file(name: { eq: "Playlist-Triptych" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img4: file(name: { eq: "BMWNOW-Carshow" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slide1: file(name: { eq: "article12-slide-1" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slide2: file(name: { eq: "article12-slide-2" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slide3: file(name: { eq: "article12-slide-3" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slide4: file(name: { eq: "article12-slide-4" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slide5: file(name: { eq: "article12-slide-5" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slide6: file(name: { eq: "article12-slide-6" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    slide7: file(name: { eq: "article12-slide-7" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slide8: file(name: { eq: "article12-slide-8" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slide9: file(name: { eq: "article12-slide-9" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    banner: file(name: { eq: "Embrace-Inspiration_Music-Live-Banner_3_Desktop" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sanityHomePage {
      exploreSectionTitle {
        _type
        en
        tc
      }
      exploreSectionParagraph {
        _type
        en
        tc
      }
      formTitle {
        _type
        en
        tc
      }
      howToJoin {
        _type
        en
        tc
      }
      howToJoinStepOne {
        _type
        en
        tc
      }
      howToJoinStepThree {
        _type
        tc
        en
      }
      howToJoinStepTwo {
        _type
        en
        tc
      }
      joinSectionParagraph {
        _type
        en
        tc
      }
      joinSectionTitle {
        _type
        en
        tc
      }
      submit {
        _type
        en
        tc
      }
      adventureTitle {
        _type
        en
        tc
      }
      embrace {
        _type
        en
        tc
      }
      freedomTitle {
        _type
        en
        tc
      }
      independenceTitle {
        _type
        en
        tc
      }
      inspirationTitle {
        _type
        en
        tc
      }
      progressTitle {
        _type
        en
        tc
      }
      wisdomTitle {
        _type
        tc
        en
      }
    }
  }
`
