import React from "react"
import Img from "gatsby-image"
import Slider from "react-slick"
import Explore from "../Home/Explore"
import Join from "../Home/Join"
import loadStates from "../../../hocs/loadStates"
import CTABtn from "../../Button/CTA"
import Arrow from "../../Icons/Arrow"

function Header({ isChinese, data }) {
  return (
    <header>
      <div className="image-container">
        <Img fluid={data.banner.childImageSharp.fluid} />
      </div>
      <div className="xxl:pt-32 md:pb-20 xxl:pb-40 pt-20 pb-32">
        <div className="md:w-full md:px-12 xxl:px-56 w-11/12 pl-40">
          <h2 className="quotes md:text-6xl bold-font mb-10">
            {isChinese
              ? "BMW INSPIRE: RETREAT - Empower Your Journey 音樂欣賞"
              : "BMW INSPIRE: RETREAT - Empower Your Journey with Music"}
          </h2>
          {isChinese ? (
            <p className="title md:text-4xl mb-20">
              ALONG WITH BMW為你呈獻 心悅 啟迪靈感 BMW INSPIRE 全方位體驗 –
              讓您善待自己，預留專屬個人時刻，尋回恬靜內心。
            </p>
          ) : (
            <p className="title md:text-4xl mb-20">
              ALONG WITH BMW presents BMW INSPIRE Embrace Inspiration
              well-rounded experience. Be kind to yourself; take time for
              self-care and find your serenity.
            </p>
          )}
          <p className="large-text md:text-4xl md:mb-0">
            {isChinese
              ? "一系列BMW INSPIRE「身、心、靈」全方位體驗讓您從運動， 心理，及自我反思等層面，認識自己，回歸真我。"
              : "With BMW, you will be able to know more about yourself from the aspects of sports, emotion, and self-reflection under “Stay Active”, “Wellness”, and “Retreat”."}
          </p>
        </div>
      </div>
    </header>
  )
}

function SectionOne({ data, isChinese }) {
  return (
    <section className="md:py-20 pb-64">
      <div className="flex justify-center">
        <div className="md:w-full w-9/12">
          <div className="image-container xxl:mb-40 mb-20">
            <Img fluid={data.img1.childImageSharp.fluid} />
          </div>
        </div>
      </div>
      <div className="md:flex-col md:px-0 xxl:pb-0 xxl:px-56 flex px-40 mb-20">
        <div className="xxl:pr-0 md:hidden relative order-2 w-6/12 pr-20">
          <InspirationSlider data={data} />
        </div>
        <div className="md:w-full xxl:px-40 xxl:pt-32 order-1 w-6/12 pt-20">
          <div className="image-container md:block relative hidden mb-20">
            <InspirationSlider data={data} />
          </div>
          <div className="xxl:w-10/12 md:w-full md:px-12 block w-11/12">
            <h3 className="title md:text-5xl bold-font md:mt-32 mb-12">
              {isChinese
                ? "MOOV x BMW Empower Your Journey Music Live 已於2020年9月22日在MOOV及BMWHK Facebook專頁線上直播，為一系列BMW INSPIRE「身、心、靈」全方位體驗揭開序幕。"
                : "MOOV x BMW Empower Your Journey Music Live was held live on 22 September 2020 on MOOV and BMWHK Facebook Page, and officially kicked off a series of “Stay Active”, “Retreat” and “Wellness” well-rounded experiences with BMW INSPIRE."}
            </h3>
            <h3 className="title md:text-5xl bold-font md:mt-32 mb-12 underline">
              {isChinese ? (
                <a
                  href="https://www.facebook.com/watch/live/?v=417601339206654&ref=watch_permalink"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  立即重溫！
                </a>
              ) : (
                <a
                  href="https://www.facebook.com/watch/live/?v=417601339206654&ref=watch_permalink"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Rewatch the the Live NOW!
                </a>
              )}
            </h3>
          </div>
        </div>
      </div>
      <div className="md:flex-col md:px-0 xxl:pb-0 xxl:px-56 flex px-40">
        <div className="xxl:pr-0 md:hidden w-6/12 pr-20">
          <div className="image-container xxl:mb-40 mb-20">
            <Img fluid={data.img3.childImageSharp.fluid} />
          </div>
        </div>
        <div className="md:w-full xxl:px-40 xxl:pt-32 w-6/12 pt-20">
          <div className="image-container md:block md:mb-10 hidden mb-20">
            <Img fluid={data.img3.childImageSharp.fluid} />
          </div>
          <div className="xxl:w-10/12 md:w-full md:px-12 block w-11/12">
            <h3 className="title md:text-5xl bold-font md:mt-0 mb-12">
              {isChinese
                ? "MOOV x BMW駕駛主題歌單及音樂自療歌單現已於MOOV平台上架。有好座駕也需要有好音樂才能享受每段路。"
                : "BMW Cantopop Playlist and Therapeutic Playlist are now available on MOOV."}
            </h3>
            {isChinese ? (
              <>
                <p className="large-text light-font md:mb-10 mb-20">
                  每一趟車程都是與自己對話的時間，除了飆風速度感的感官刺激外，在專注的同時覺察自己內心狀態，讓你自在地駕馭真我。
                </p>
                <p className="large-text light-font md:mb-10 mb-20">
                  立刻登上座駕，與BMW一起展開悠然自得之旅！
                </p>
                <p className="large-text light-font">
                  心悅．自駕悠：
                  <a
                    href="https://s.moov.hk/r?s=7vXAVp"
                    target="_blank"
                    className="font-bold underline"
                  >
                    按此瀏覽更多
                  </a>
                  <br />
                  心悅．敢於探索：
                  <a
                    href="https://s.moov.hk/r?s=NZp7bT"
                    target="_blank"
                    className="font-bold underline"
                  >
                    按此瀏覽更多
                  </a>
                  <br />
                  心悅．尋回恬靜初心：
                  <a
                    href="https://s.moov.hk/r?s=fIO4Zl"
                    target="_blank"
                    className="font-bold underline"
                  >
                    按此瀏覽更多
                  </a>
                  <br />
                </p>
              </>
            ) : (
              <>
                <p className="large-text light-font md:mb-10 mb-20">
                  With music therapy, you can express yourself, meet your needs
                  and find your inner peace. Let the music accompany you on
                  every journey.
                </p>
                <p className="large-text light-font md:mb-10 mb-20">
                  Start your journey with BMW now!
                </p>
                <p className="large-text light-font -mr-20">
                  Playlist 1 - 心悅．自駕悠：
                  <a
                    href="https://s.moov.hk/r?s=7vXAVp"
                    target="_blank"
                    className="font-bold underline"
                  >
                    Click here to explore more
                  </a>
                  <br />
                  Playlist 2 - 心悅．敢於探索：
                  <a
                    href="https://s.moov.hk/r?s=NZp7bT"
                    target="_blank"
                    className="font-bold underline"
                  >
                    Click here to explore more
                  </a>
                  <br />
                  Playlist 3 - 心悅．尋回恬靜初心：
                  <a
                    href="https://s.moov.hk/r?s=fIO4Zl"
                    target="_blank"
                    className="font-bold underline"
                  >
                    Click here to explore more
                  </a>
                  <br />
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

function InspirationSlider({ data }) {
  const slider = React.useRef(null)
  const [slideIndex, setSlideIndex] = React.useState(0)
  const nextSlide = () => {
    slider.current && slider.current.slickNext()
  }
  const prevSlide = () => {
    slider.current && slider.current.slickPrev()
  }
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    initialSlide: 0,
    beforeChange: (current, next) => {
      setSlideIndex(next)
    },
  }
  return (
    <>
      <div
        className={`arrow-container arrow-container--next absolute right-0 z-10 cursor-pointer transform top-1/2 -translate-y-1/2  ${
          slideIndex === 8 ? "opacity-0 invisible" : "opacity-1 visible"
        }`}
        onClick={() => nextSlide()}
      >
        <div className="py-6 text-white bg-gray-700">
          <Arrow />
        </div>
      </div>
      <div
        className={`arrow-container arrow-container--prev absolute left-0 z-10 cursor-pointer transform top-1/2 -translate-y-1/2  ${
          slideIndex === 0 ? "opacity-0 invisible" : "opacity-1 visible"
        }`}
        onClick={() => prevSlide()}
      >
        <div className="py-6 text-white transform rotate-180 bg-gray-700">
          <Arrow />
        </div>
      </div>
      <Slider {...settings} ref={slider}>
        <div className="slide">
          <Img fluid={data.slide1.childImageSharp.fluid} />
        </div>
        <div className="slide">
          <Img fluid={data.slide2.childImageSharp.fluid} />
        </div>
        <div className="slide">
          <Img fluid={data.slide3.childImageSharp.fluid} />
        </div>
        <div className="slide">
          <Img fluid={data.slide4.childImageSharp.fluid} />
        </div>
        <div className="slide">
          <Img fluid={data.slide5.childImageSharp.fluid} />
        </div>
        <div className="slide">
          <Img fluid={data.slide6.childImageSharp.fluid} />
        </div>
        <div className="slide">
          <Img fluid={data.slide7.childImageSharp.fluid} />
        </div>
        <div className="slide">
          <Img fluid={data.slide8.childImageSharp.fluid} />
        </div>
        <div className="slide">
          <Img fluid={data.slide9.childImageSharp.fluid} />
        </div>
      </Slider>
    </>
  )
}

function Article({ sanity, lang: { locale } }) {
  const isChinese = locale === "tc"
  return (
    <div>
      <Header isChinese={isChinese} data={sanity} />
      <SectionOne isChinese={isChinese} data={sanity} />
      <Explore home={sanity.sanityHomePage} />
      <Join home={sanity.sanityHomePage} />
      <CTABtn />
    </div>
  )
}

export default loadStates(Article)
